<template>
  <!-- 页面name：督导模板 -->
  <div class="contain">
    <div class="form-header-btn">
      <div class="header-title">督导模板</div>
      <div>
        <el-button
          type="primary"
          class="sheet-btn"
          v-if="isAdd"
          @click="addTemplate"
          icon="el-icon-plus"
          >新增模板</el-button
        >
      </div>
    </div>
    <!-- 表单 -->
    <el-form
      :model="form"
      onsubmit="return false"
      :inline="true"
      class="search-form"
    >
      <el-form-item>
        <el-input
          v-model.trim="form.templateName"
          placeholder="请输入模板名称"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.label"
          clearable
          filterable
          placeholder="请选择标签"
        >
          <el-option
            v-for="item in labelOptions"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.supervisionMethod"
          filterable
          clearable
          placeholder="请选择督导方式"
        >
          <el-option
            v-for="item in superviseTypeOptions"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.isEnable" clearable placeholder="请选择状态">
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="searchFun"
          >查询</el-button
        >
        <el-button icon="el-icon-refresh-right" @click="resetFun"
          >重置</el-button
        >
      </el-form-item>
    </el-form>
    <l-table
      :columns="columns"
      :dataSource="tableData"
      :pagination="pageFlag"
      :ipagination="page"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :loading="tableLoading"
      :showXh="true"
      :height="'calc(100% - 2.91667vw)'"
      :autoHeight="false"
    >
      <el-table-column
        prop="templateName"
        label="模板名称"
        align="center"
        slot="templateName"
      ></el-table-column>
      <el-table-column
        prop="labelName"
        label="标签"
        align="center"
        slot="labelName"
      ></el-table-column>
      <el-table-column
        prop="supervisionMethodName"
        label="督导方式"
        align="center"
        slot="supervisionMethodName"
      ></el-table-column>
      <el-table-column
        prop="totalScore"
        label="总分"
        align="center"
        slot="totalScore"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="sort"
        label="排序"
        width="100"
        align="center"
        slot="sort"
      ></el-table-column>
      <el-table-column
        prop="isEnable"
        label="状态"
        align="center"
        width="180"
        slot="isEnable"
      >
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.isEnableFlag"
            active-color="#003685"
            inactive-color="#C5C3D8"
            :active-text="scope.row.isEnable == '0' ? '禁用' : '启用'"
            :inactive-text="''"
            :disabled="
              !isUpdate || (isUpdate && userInfo.userId !== scope.row.createBy)
            "
            @change="
              (val) => handleTemplateEnableChange(val, scope.row, scope.$index)
            "
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        prop="description"
        slot="description"
        label="描述"
        width="220"
        align="center"
      >
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="light"
            popper-class="customPopper"
            :content="scope.row.description"
            placement="top"
            v-if="scope.row.description"
          >
            <div class="description-div">{{ scope.row.description }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="操作" slot="handle" align="center" width="250">
        <template slot-scope="scope">
          <el-button
            class="table-handle"
            @click="handleTemplateFun('publish', scope.row)"
            type="text"
            v-if="isPublish"
            >发布</el-button
          >
          <el-button
            class="table-handle"
            @click="handleTemplateFun('copy', scope.row)"
            type="text"
            v-if="isCopy"
            >复制</el-button
          >
          <el-button
            class="table-handle"
            @click="handleTemplateFun('view', scope.row)"
            type="text"
            v-if="isView"
            >查看</el-button
          >
          <el-button
            class="table-handle"
            @click="handleTemplateFun('edit', scope.row)"
            type="text"
            v-if="isUpdate && userInfo.userId === scope.row.createBy"
            >编辑</el-button
          >
          <el-popconfirm
            confirm-button-text="确定"
            cancel-button-text="取消"
            icon="el-icon-warning"
            icon-color="#E6BD89"
            title="你确定要删除此模板吗？"
            v-if="isDelete && userInfo.userId === scope.row.createBy"
            @confirm="handleDel(scope.row)"
          >
            <el-button
              slot="reference"
              type="text"
              class="table-handle"
              v-if="isDelete && userInfo.userId === scope.row.createBy"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </l-table>
  </div>
</template>

<script>
import LTable from "@/components/tableComponent/index.vue";
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
export default {
  name: "superviseTemplate",
  components: { LTable },
  data() {
    return {
      dialogVisible: false,
      tableLoading: false, // tableloading
      page: {
        num: 1,
        size: 10,
        total: 0,
      }, // 分页信息
      pageFlag: false,
      columns: [
        {
          label: "模板名称",
          slotName: "templateName",
        },
        {
          label: "标签",
          slotName: "labelName",
        },
        {
          label: "督导方式",
          slotName: "supervisionMethodName",
        },
        {
          label: "总分",
          slotName: "totalScore",
        },
        {
          label: "排序",
          slotName: "sort",
        },
        {
          label: "状态",
          slotName: "isEnable",
        },
        {
          label: "描述",
          slotName: "description",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ],
      title: "",
      form: {
        templateName: "",
        label: "",
        supervisionMethod: "",
        isEnable: "",
      },
      labelOptions: [
        {
          name: "常规督导",
          value: "1",
        },
        {
          name: "专项督导",
          value: "2",
        },
      ], //标签下拉
      superviseTypeOptions: [
        {
          name: "远程督导",
          value: "1",
        },
        {
          name: "现场督导",
          value: "2",
        },
      ], //督导方式下拉
      statusOptions: [
        {
          name: "启用",
          value: "1",
        },
        {
          name: "禁用",
          value: "0",
        },
      ], //状态下拉
      tableData: [], //表单数据
      userInfo: {}, //登录人信息

      permissionButtonList: [], // 权限按钮list
      isAdd: false, //新增
      isPublish: false, //发布
      isView: false, //查看
      isCopy: false, //复制
      isUpdate: false, //编辑
      isDelete: false, //删除
    };
  },
  computed: {
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isAdd = val.indexOf("z_superviseTemplate:add") != -1;
          this.isPublish = val.indexOf("z_superviseTemplate:deploy") != -1;
          this.isView = val.indexOf("z_superviseTemplate:view") != -1;
          this.isCopy = val.indexOf("z_superviseTemplate:copy") != -1;
          this.isUpdate = val.indexOf("z_superviseTemplate:edit") != -1;
          this.isDelete = val.indexOf("z_superviseTemplate:del") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    let userInfo = sessionStorage.getItem("userInfo")
      ? JSON.parse(sessionStorage.getItem("userInfo"))
      : {};
    this.userInfo = userInfo;

    this.tableLoading = true;
    // this.init();
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
  },
  activated(){
    this.init();
  },
  methods: {
    getPermissionButton,
    isHasPermission,
    /** 初始化 */
    init() {
      // if (this.form.keyword == "") {
      //   this.form.keyword = null;
      // }
      // this.page.num = 1;
      let param = {};
      for (let key in this.form) {
        if (this.form[key]) {
          param[key] = this.form[key];
        }
      }
      param.level = "1";
      param.current = this.page.num;
      param.size = this.page.size;
      this.$api
        .getTemplateList(param)
        .then((res) => {
          if (res.data.code == 0) {
            this.tableLoading = false;
            res.data.data.records.map((item) => {
              let labelName = "";
              let labelIndex = this.labelOptions.findIndex((labelInfo) => {
                return labelInfo.value == item.label;
              });
              if (labelIndex != -1) {
                labelName = this.labelOptions[labelIndex].name;
              }
              item.labelName = labelName;

              let superviseTypeName = "";
              let superviseTypeIndex = this.superviseTypeOptions.findIndex(
                (superviseTypeInfo) => {
                  return superviseTypeInfo.value == item.supervisionMethod;
                }
              );
              if (superviseTypeIndex != -1) {
                superviseTypeName =
                  this.superviseTypeOptions[superviseTypeIndex].name;
              }
              item.supervisionMethodName = superviseTypeName;

              item.isEnableFlag = item.isEnable === "1" ? true : false;
            });
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
            if (res.data.data.total >= 1) {
              this.pageFlag = true;
            } else {
              this.pageFlag = false;
            }
          } else {
            this.tableLoading = false;
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    // 查询
    searchFun() {
      this.page.num = 1;
      this.tableLoading = true;
      this.init();
    },
    // 重置按钮
    resetFun() {
      this.form.templateName = "";
      this.form.label = "";
      this.form.supervisionMethod = "";
      this.form.isEnable = "";
      this.page.num = 1;
      this.tableLoading = true;
      this.init();
    },
    /** 新增模板 */
    addTemplate() {
      this.$router.push({
        path: "/superviseTemplateDetail",
        query: { type: "add", jumpType: "headquarters" },
      });
    },
    /** 行内-编辑 */
    handleTemplateFun(type, row) {
      switch (type) {
        case "publish":
          this.$router.push({
            path: "/superviseTaskDetail",
            query: {
              templateId: row.templateId,
              type: "add",
              jumpType: "headquarters",
            },
          });
          break;
        case "copy":
          this.$router.push({
            path: "/superviseTemplateDetail",
            query: {
              id: row.templateId,
              type: "copy",
              jumpType: "headquarters",
            },
          });
          break;
        case "view":
          this.$router.push({
            path: "/superviseTemplateDetail",
            query: {
              id: row.templateId,
              type: "view",
              jumpType: "headquarters",
            },
          });
          break;
        case "edit":
          this.$router.push({
            path: "/superviseTemplateDetail",
            query: {
              id: row.templateId,
              type: "edit",
              jumpType: "headquarters",
            },
          });
          break;
        default:
          break;
      }
    },
    //改变状态
    handleTemplateEnableChange(val, row, index) {
      let list = JSON.parse(JSON.stringify(this.tableData));
      let param = {
        id: row.templateId,
        isEnable: val ? "1" : "0",
      };
      this.$api
        .templateUpdateStatus(param)
        .then((res) => {
          if (res.data.code == 0) {
            list[index].isEnable = val ? "1" : "0";
            list[index].isEnableStatus = val ? true : false;
            this.tableData = list;
          } else {
            this.$message.error(res.data.msg);
            list[index].isEnable = val ? "0" : "1";
            list[index].isEnableStatus = val ? false : true;
            this.tableData = list;
          }
        })
        .catch(() => {
          list[index].isEnable = val ? "0" : "1";
          list[index].isEnableStatus = val ? false : true;
          this.tableData = list;
        });
    },
    /** 行内-删除 */
    handleDel(row) {
      this.$api
        .removeTemplate(row.templateId)
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
              duration: 2000,
              onClose: () => {
                this.tableLoading = true;
                this.init();
              },
            });
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {});
    },
    /** 分页 */
    handleCurrentChange(val) {
      console.log(val);
      this.page.num = val;
      this.tableLoading = true;
      this.init();
    },
    handleSizeChange(val) {
      console.log(val);
      this.page.size = val;
      this.page.num = 1;
      this.tableLoading = true;
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  background-color: #ffffff;
  height: calc(100% - 56px);
  padding: 26px 30px 30px 30px;
  .form-header-btn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .header-title {
      color: #3d3e4f;
      font-size: 22px;
      font-weight: bold;
    }
    .sheet-btn {
      background: #003685;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
      width: auto;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }
  /deep/.el-table {
    // margin-top: 14px;
  }
  /deep/.el-table th > .cell {
    padding-left: 14px;
    border-left: 2px solid #e4ebf5;
  }
  /deep/.el-table th:first-child > .cell {
    border-left: transparent;
  }
  /deep/.el-switch__label.is-active {
    color: #010334;
  }
  /deep/.el-switch__label {
    color: #010334;
  }
  .table-handle {
    margin-right: 10px;
    margin-left: 0;
  }
  .tableList {
    height: calc(100% - 115px);
  }
}
</style>
